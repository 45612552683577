import InstantClick from 'instantclick'
//InstantClick.init();

if (typeof window.gtag === "function") {
    InstantClick.on('change', function() {
      console.log('instantclick.change');
      window.gtag('config', 'UA-18235642-1', {
        'page_title' : document.title,
        'page_url': location.href,
        'anonymize_ip': true
      });
    });
}
